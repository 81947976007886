import request from "@/utils/request";
import qs from 'qs'
import { version } from "xlsx";

/* 登录相关 */

/* 登录接口 */
export const apiLogin = (params) => {
    return request.post(`/sys/user/login?${qs.stringify(params, { indices: false })}`)
}

/* 登出接口 */
export const apiLogout = () => {
    return request.post('/sys/user/logout')
}


/* 登录时获取的code码 */
export const apiLoginCode = () => {
    return request.get('/sys/user/login/code')
}


/* 用户修改密码 */
export const apiUpdatePassword = (params) => {
    return request.post(`/sys/user/updatePswd?${qs.stringify(params, { indices: false })}`)
}


/* 用户权限 */
export const apiGetUserPerms = () => {
    return request.post("/sys/user/perms")
}


/* 获取用户信息 */
export const apiGetUserInfo = (params) => {
    return request.get("/sys/user/infoUser", params)
}

/* 更新用户信息 */
export const apiUpdateUserInfo = (params) => {
    return request.post('/sys/user/update', params)
}


/* 设置工作时间 */
export const apiSetUserWorkHours = (params) => {
    return request.post("/sys/user/saveWorkHours", params)
}

/* 获取工作时间 */
export const apiGetUserWorkHours = () => {
    return request.get("/sys/user/getWorkHoursDetail")
}


/* 请求服务器接口信息提交到服务器 */
export const apiApiDataSendServe = (data) => {
    try {
        const saveData = {
            platform: "haozhixue-manage",
            version: process.env.VUE_APP_VERSION,
            ...data,
        }
        const xhr = new XMLHttpRequest();
        const url = '/v2/api/saveServeApiData';
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                console.log(xhr.responseText);
            }
        };
        xhr.onerror = () => { }
        xhr.send(JSON.stringify(saveData));
    } catch (err) {

    }

}